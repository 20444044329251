import React, { useEffect, useState } from "react";
import dynamic from "next/dynamic";
import PropTypes from 'prop-types';
const InterstitialPopupBlock = dynamic(() => import("../Mixture/View/InterstitialPopupBlock"));
import { CampaignIframeOverlayConstants } from "../../adapters/helpers/Constants";
import { getCookieValue } from '../../adapters/helpers/Utils';
import { mediaQueryHOC } from '../../adapters/helpers/Hooks';

const InterstitialSignUpBlock = (props) => {
    const { document: doc } = props;
    const { isXSmall, isSmall } = props;
    const isMobile = isXSmall || isSmall;
    const expiryHours = doc?.fields?.interstitialBlock?.fields?.expiryHours
    const [openInterstitial, setOpenInterstitial] = useState(false);
    const [isInterstitialButtonEnabled, setIsInterstitialButtonEnabled] = useState(false);

    const interestitialEventPush = (eventCategory, eventAction, eventLabel) => {
        if (typeof (window.dataLayer) !== 'undefined') {
            window.dataLayer.push({
                'event': CampaignIframeOverlayConstants?.customEvent,
                'GAeventCategory': eventCategory,
                'GAeventAction': eventAction,
                'GAeventLabel': eventLabel
            });
        }
    };

    const openInterstitialBlock = () => {
        if (!openInterstitial) {
            setOpenInterstitial(true);
        }
    }

    const closeInterstitialBlock = () => {
        setTimeout(() => {
            setOpenInterstitial(false);
            setTimeout(() => {
                setIsInterstitialButtonEnabled(false)
            },15000)
            const date = new Date();
            date.setHours(parseInt(expiryHours)); // for one day validaity
            const expires = `expires=${date.toUTCString()}`;
            document.cookie = `${CampaignIframeOverlayConstants.isOpenIntersistialPopUpCookie}=ok; ${expires}; path=/; SameSite=Lax`;
        })
    }

    const disableInterstialButton = () => {
        setTimeout(() => {
            setIsInterstitialButtonEnabled(false);
        })
    }

    useEffect(() => {
        setTimeout(() => {
            const isCookieIntertitialEnable = !getCookieValue(CampaignIframeOverlayConstants.disableIntersistialPopUpCookie) ? true : false;
            const isOpenIntersistialPopUpCookie = !getCookieValue(CampaignIframeOverlayConstants.isOpenIntersistialPopUpCookie) ? true : false;
            setIsInterstitialButtonEnabled(isOpenIntersistialPopUpCookie);
            setOpenInterstitial(isOpenIntersistialPopUpCookie);
        },7000)
    }, []);

    return (
        isInterstitialButtonEnabled && <div className='ob-interstitial-overlay-button'>
             <button id="Interstitial_Button" aria-label="Sign Up and Save"  aria-hidden="false" role="complementary" onClick={openInterstitialBlock}>
                {
                    isMobile ? <img src={doc?.fields?.mobileOverlayButton?.fields?.asset?.fields?.file?.url} alt={doc?.fields?.mobileOverlayButton?.fields?.alternateText} className='ob-interstitial-button__image' /> :
                        <img src={doc?.fields?.desktopOverlayButton?.fields?.asset?.fields?.file?.url} alt={doc?.fields?.desktopOverlayButton?.fields?.alternateText} className='ob-interstitial-button__image' />
                }
                {openInterstitial && isInterstitialButtonEnabled && <InterstitialPopupBlock expiryHours={expiryHours} document={doc?.fields?.interstitialBlock} closeInterstitialBlock={() => {
                    closeInterstitialBlock();
                }} disableInterstialButton={() => {
                    disableInterstialButton();
                }} />}
             </button>  
        </div>
    )
}

export default mediaQueryHOC(InterstitialSignUpBlock);

InterstitialSignUpBlock.propTypes = {
    document: PropTypes.any,
    isMedium: PropTypes.bool,
    isLarge: PropTypes.bool,
    isXXLarge: PropTypes.bool,
    isSmall: PropTypes.bool,
    isXSmall: PropTypes.bool,
};