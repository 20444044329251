import React from 'react'
import DOMPurify from 'isomorphic-dompurify';

function ConsentBlock(props) {

    const activateCookieConsentEU = props?.document?.fields?.activateCookieConsentEU;
    const cookieConsentLabel = props?.document?.fields?.cookieConsentLabel?.fields?.text;
    const activateDoNotSellMyData = props?.document?.fields?.activateDoNotSellMyData;
    const doNotSellMyDataLabel = props?.document?.fields?.doNotSellMyDataLabel?.fields?.text;

    return (
        <>
            {(activateCookieConsentEU && cookieConsentLabel) &&
                <span className='ob-eu-cookie-consent' dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(`
                <button aria-label=${cookieConsentLabel} onclick="Optanon.ToggleInfoDisplay();">${cookieConsentLabel}</button>
                `)}}>
                </span>
            }
            {(doNotSellMyDataLabel && activateDoNotSellMyData) &&
                <span className='ob-us-personal-data' dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(`
                        <button aria-label=${doNotSellMyDataLabel} onclick="Optanon.ToggleInfoDisplay();">${doNotSellMyDataLabel}</button>
                        `)}}>
                </span>
            }
        </>
    )
}

export default ConsentBlock;

